<template>
  <transition
    enter-active-class="transitionEnter animated slideInDown faster"
    leave-active-class="transitionLeave animated slideOutUp faster"
    mode="out-in"
    appear
  >
    <div class="city-center-parent">
      <div class="bg"></div>

      <!-- PORTAL TEXT -->
      <!-- <div class="portal-text">
				<h1>
					<span class="text">
						HELLO WORLD THIS IS MITCH
					</span>
				</h1>
			</div> -->
    </div>
  </transition>
</template>

<script>
  // import { mapGetters } from 'vuex';

  export default {
    computed: {
      // ...mapGetters({
      // getActiveTask: 'tasks/getActiveTask',
      // isLobby: 'scenes/isLobby',
      // })
    },
  };
</script>

<style lang="scss" scoped>
  .city-center-parent,
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -3; // [LOCKED]
    // filter: blur(5px);
  }

  //-----------------------------------------------------------------
  // WALLPAPER
  //-----------------------------------------------------------------

  .bg {
    background: #5b5e6a url("./assets/img/city-center.jpg") no-repeat center center;
    background-size: 1920px;
    overflow: hidden;
    // z-index: -1;
  }

  .portal-text {
    position: absolute;
    top: rem-calc(300);
    left: rem-calc(1005);
    width: rem-calc(338);
    height: rem-calc(350);
    padding: rem-calc(15);

    color: grey;
    // border: 2px solid white;
    transform: rotate(-3deg) translate3d(3px, 0, 0) perspective(400px) rotateX(10deg)
      rotateY(-10deg);
    z-index: 1;

    h1 {
      filter: blur(1px);
      text-shadow: 5px 5px 5px rgba(black, 0.05);
      font-size: rem-calc(100);
      line-height: 0.8;
    }
  }

  // PAUSE MOTION
  // .is-performance-mode .base-wallpaper .base-wallpaper-icons {
  // 	animation: none;
  // }
</style>
